import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
// utils
import axiosBrightHub from 'src/utils/axiosBrightHub';
//
import { RootState, AppDispatch } from '../store';

// types
import { IUserItem, INewUserItem, IInviteUserItem, IUserLocation } from 'src/types/user';

// ----------------------------------------------------------------------

type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

interface IUserResponse {
    me?: IUserItem;
    user?: INewUserItem;
    users?: INewUserItem[];
    locations?: IUserLocation[];
    error?: string;
    message?: string;
}

interface IPasswordChangeTicketResponse {
    url: string;
    expired?: boolean;
}

interface UsersState {
    isLoading: boolean;
    error: string | null;
    users: INewUserItem[];
    locations?: IUserLocation[];
    me: IUserItem | null;
    user: INewUserItem | null;
    sortBy: string | null;
    passwordChangeUrl: string | null;
    passwordTicketExpired?: boolean | null;
}

const initialState: UsersState = {
    isLoading: false,
    error: null,
    user: null,
    me: null,
    users: [],
    passwordChangeUrl: null,
    passwordTicketExpired: null,
    locations: [],
    sortBy: 'createdAt',
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ACCOUNTS
        getUsersSuccess(state, action: PayloadAction<IUserResponse>) {
            state.isLoading = false;
            state.users = action.payload.users || [];
        },

        // GET MY USER
        getMyUserSuccess(state, action: PayloadAction<IUserResponse>) {
            state.isLoading = false;
            state.me = action.payload.me || null;
        },

        // UPDATE ACCOUNT
        updateUserSuccess(state, action: PayloadAction<IUserResponse>) {
            state.isLoading = false;
            if (action.payload.error) {
                state.error = action.payload.message || null;
            } else {
                const user_data = action.payload.user;
                if (user_data) {
                    const userIndex = state.users.findIndex(user => user.userId === user_data.userId);
                    if (userIndex !== -1) {
                        state.users[userIndex] = user_data; // Ensure we only update when user_data is not null.
                    }
                }
            }
        },

        // GET ACCOUNT
        getUserSuccess(state, action: PayloadAction<IUserResponse>) {
            state.isLoading = false;
            state.user = action.payload.user || null;
        },

        // INVITE USER SUCCESS
        inviteUserSuccess(state, action: PayloadAction<IUserResponse>) {
            state.isLoading = false;
            if (action.payload.user) {
                state.users.push(action.payload.user);
            } else if (action.payload.error) {
                state.error = action.payload.message || null;
            }
        },

        // GET ACCOUNT LOCATIONS
        getUserLocationsSuccess(state, action: PayloadAction<IUserResponse>) {
            state.isLoading = false;
            state.user = action.payload.user || null;
            state.locations = action.payload.locations ?? [];
        },

        // UPDATE PASSWORD RESET URL
        updatePasswordResetUrl(state, action: PayloadAction<IPasswordChangeTicketResponse>) {
            state.isLoading = false;
            state.passwordChangeUrl = action.payload.url;
            state.passwordTicketExpired = action.payload?.expired;
        },

        //  SORT & FILTER PRODUCTS
        sortByUsers(state, action: PayloadAction<string>) {
            state.sortBy = action.payload;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    hasError,
    startLoading,
    sortByUsers,
    getUserSuccess,
    getUsersSuccess,
    getMyUserSuccess,
    inviteUserSuccess,
    updateUserSuccess,
    updatePasswordResetUrl,
    getUserLocationsSuccess
} = slice.actions;

// ----------------------------------------------------------------------

export function getMyUser() {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.get('/management/user/me');
            dispatch(getMyUserSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function inviteUser(data: IInviteUserItem) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/management/user/invite', data);
            dispatch(inviteUserSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getUsers() {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            let url = '/management/user/get_users';
            const response = await axiosBrightHub.get(url);
            dispatch(getUsersSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getUsersForBrightAdmins() {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            let url = '/user/manage/get_users';
            const response = await axiosBrightHub.get(url);
            dispatch(getUsersSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getLocationsByUser(user: string, app?: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            let url = '/user/manage/get_locations_by_user?app=' + (app || 'brightpay') + '&user_id=' + user;
            const response = await axiosBrightHub.get(url);
            dispatch(getUserLocationsSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export const getUser = (userId: string): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/user/manage/get_user', { userId });
        dispatch(getUserSuccess(response.data.user));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
};

// ----------------------------------------------------------------------

export function updateUser(userId: string, data: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/user/manage/update', { userId, data });
            dispatch(updateUserSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getAuth0PasswordChangeUrl(userId: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.get('/auth/user/get_auth0_password_change_url');
            dispatch(updatePasswordResetUrl(response.data));
        } catch (error) {
            console.error(error);
            dispatch(hasError(error));
        }
    };
}