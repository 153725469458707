import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
// utils
import axios from 'src/utils/axios';
import axiosBrightHub from 'src/utils/axiosBrightHub';
import uuidv4 from 'src/utils/uuidv4';
//
import { RootState, AppDispatch } from '../store';

// types
import {
    IService,
    IServiceCategory,
    IServicePathway,
    IServicePathwaysData,
    IServicePathwayStep
} from 'src/types/services';

import { IPractitionerSpecialty } from 'src/types/practitioner';

interface CustomFile extends File {
    id?: string;
    path?: string;
    preview?: string;
    lastModifiedDate?: Date;
}


// ----------------------------------------------------------------------

type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

interface IServicesResponse {
    services?: IService[];
    count?: number;
    stats?: {
        [key: string]: number;
    };
}

interface IServiceResponse {
    service?: IService;
    pathwaysData?: IServicePathwaysData;
    status?: string;
    error?: string;
}

interface ServicesState {
    isLoading: boolean;
    error: string | null;
    categories: IServiceCategory[];
    specialties: IPractitionerSpecialty[];
    services?: IService[];
    service?: IService | null;
    pathwaysData?: IServicePathwaysData | null;
    signedUrls?: {
        fieldName: string;
    }
}

const initialState: ServicesState = {
    isLoading: false,
    error: null,
    categories: [],
    specialties: [],
    services: [],
    service: null,
    pathwaysData: null,
};

const slice = createSlice({
    name: 'services',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET SERVICES
        getServicesSuccess(state, action: PayloadAction<IServicesResponse>) {
            state.isLoading = false;
            state.services = action.payload.services || [];
        },

        // GET CATEGORIES
        getCategoriesSuccess(state, action: PayloadAction<IServiceCategory[]>) {
            state.isLoading = false;
            state.categories = action.payload || [];
        },

        // GET SPECIALTIES
        getSpecialtiesSuccess(state, action: PayloadAction<IPractitionerSpecialty[]>) {
            state.isLoading = false;
            state.specialties = action.payload || [];
        },

        // CREATE SERVICE
        createServiceSuccess(state, action: PayloadAction<IServiceResponse>) {
            state.isLoading = false;
            state.error = action.payload.error || 'Failed to create service';
            state.service = action.payload?.service;
        },

        // CLEAR SERVICE STATE
        clearServiceState(state) {
            state.isLoading = false;
            state.error = null;
            state.service = null;
        },

        // GET SERVICE CONTENT
        getServiceContentSuccess(state, action: PayloadAction<IServiceResponse>) {
            state.isLoading = false;
            state.service = action.payload?.service;
        },

        // UPDATE SERVICE CONTENT
        updateServiceContentSuccess(state, action: PayloadAction<IServiceResponse>) {
            state.isLoading = false;
            state.error = action.payload.status === 'success' ? null : 'Failed to update service content';
        },

        // UPDATE SERVICE METADATA
        updateServiceMetadataSuccess(state, action: PayloadAction<IServiceResponse>) {
            state.isLoading = false;
            state.error = action.payload.status === 'success' ? null : 'Failed to update service metadata';
        },

        // PUBLISH SERVICE CONTENT
        publishServiceContentSuccess(state, action: PayloadAction<IServiceResponse>) {
            state.isLoading = false;
            state.error = action.payload.status === 'success' ? null : 'Failed to publish service content';
        },

        // GET SERVICE PATHWAYS
        getServicePathwaysSuccess(state, action: PayloadAction<IServiceResponse>) {
            state.isLoading = false;
            state.pathwaysData = action.payload.pathwaysData;
            state.error = null;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    hasError,
    startLoading,
    clearServiceState,
    getServicesSuccess,
    createServiceSuccess,
    getCategoriesSuccess,
    getSpecialtiesSuccess,
    getServiceContentSuccess,
    getServicePathwaysSuccess,
    updateServiceContentSuccess,
    updateServiceMetadataSuccess,
    publishServiceContentSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCategories() {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/master/get_all_categories');
            dispatch(getCategoriesSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getAllSpecialties() {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/master/get_all_specialties');
            dispatch(getSpecialtiesSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServicesByCategories(categories: string[]) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/master/get_services_by_categories', { categories: categories });
            dispatch(getServicesSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createService(service: string, category: string, slug: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/master/create', { service, category, slug });
            dispatch(createServiceSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceContent(serviceId: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/master/get_service_content', { serviceId });
            dispatch(getServiceContentSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateServiceContent(serviceId: string, serviceSlug: string, data: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/master/update_service_content', { serviceId, serviceSlug, data });
            dispatch(updateServiceContentSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateServiceMetadata(serviceId: string, serviceSlug: string, data: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/master/update_service_metadata', { serviceId, serviceSlug, data });
            dispatch(updateServiceMetadataSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function publishServiceContent(serviceSlug: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/master/publish_service_content', { serviceSlug });
            dispatch(updateServiceContentSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function uploadImage(imageType: string, serviceSlug: string, file: CustomFile) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/master/create_signed_url', { imageType, serviceSlug, fileId: file.id, fileName: file.path });
            if (response.data) {
                await axios.put(response.data.signedUrl, file, {
                    headers: {
                        'Content-Type': file.type,
                        'Content-Encoding': 'base64',
                        'Skip-Auth': 'true'
                    }
                });
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function uploadContentImageAndReturnUrl(imageType: string, serviceSlug: string, file: File) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        console.log('Came to Uploading Image inside editor')
        const fileId = uuidv4()
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/master/create_signed_url', { imageType, serviceSlug, fileId: fileId, fileName: file.name });
            if (response.data) {
                const imageUrl = response.data.fileUrl
                await axios.put(response.data.signedUrl, file, {
                    headers: {
                        'Content-Type': file.type,
                        'Content-Encoding': 'base64',
                        'Skip-Auth': 'true'
                    }
                });
                return imageUrl
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServicePathways(serviceId: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/pathways/get', { serviceId });
            dispatch(getServicePathwaysSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function createServicePathway(serviceId: string, pathwayId: string, pathwayName: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/pathways/add_pathway', { serviceId, pathwayId, pathwayName });
            dispatch(getServicePathwaysSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function moveServicePathway(serviceId: string, ordered: string[]) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/pathways/move_pathway', { serviceId, ordered });
            dispatch(getServicePathwaysSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error.toString()));
        }
    };
}

// ----------------------------------------------------------------------

export function updateServicePathwayName(serviceId: string, pathwayId: string, pathwayName: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/pathways/update_pathway_name', { serviceId, pathwayId, pathwayName });
            dispatch(getServicePathwaysSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error.toString()));
        }
    };
}

// ----------------------------------------------------------------------

export function deleteServicePathway(serviceId: string, pathwayId: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/pathways/delete_pathway', { serviceId, pathwayId });
            dispatch(getServicePathwaysSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error.toString()));
        }
    };
}

// ----------------------------------------------------------------------

export function addPathwayStep(serviceId: string, pathwayId: string, stepData: IServicePathwayStep) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/pathways/add_pathway_step', { serviceId, pathwayId, stepData });
            dispatch(getServicePathwaysSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updatePathwayStep(serviceId: string, pathwayId: string, stepData: IServicePathwayStep) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/pathways/update_pathway_step', { serviceId, pathwayId, stepData });
            dispatch(getServicePathwaysSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function movePathwayStep(serviceId: string, pathwayId: string, stepsOrdered: string[]) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/pathways/move_pathway_step', { serviceId, pathwayId, stepsOrdered });
            dispatch(getServicePathwaysSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function deletePathwayStep(serviceId: string, pathwayId: string, stepId: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brighthealth/services/pathways/delete_pathway_step', { serviceId, pathwayId, stepId });
            dispatch(getServicePathwaysSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}