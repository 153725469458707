import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
// utils
import axiosBrightHub from 'src/utils/axiosBrightHub';
//
import { RootState, AppDispatch } from '../store';

// types
import { IPractitionerItem } from 'src/types/practitioner';

// ----------------------------------------------------------------------

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

interface IPractitionerResponse {
  practitioner?: IPractitionerItem;
  practitioners?: IPractitionerItem[];
  stats?: {
    [key: string]: number;
  };
  error?: string;
  message?: string;
}

interface VpaQrImageDataResponse {
  providerId: string;
  qrImage: string;
}

interface IPractitionerFlagsResponse {
  providerIds: string[];
  status?: string;
  bookmarked?: boolean;
  error?: string;
  message?: string;
}

interface PractitionersState {
  isLoading: boolean;
  error: string | null;
  practitioners: IPractitionerItem[];
  vpaQrImages: {
    [key: string]: string | null;
  },
  stats: {
    [key: string]: number;
  };
  practitioner: IPractitionerItem | null;
  category: string | null;
  other: string | null;
  sortBy: string | null;
}

const initialState: PractitionersState = {
  isLoading: false,
  error: null,
  practitioners: [],
  vpaQrImages: {},
  stats: {},
  practitioner: null,
  category: null,
  other: null,
  sortBy: 'createdAt',
};

const slice = createSlice({
  name: 'practitioner',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ACCOUNTS
    getPractitionersSuccess(state, action: PayloadAction<IPractitionerResponse>) {
      state.isLoading = false;
      const newPractitioners = action.payload.practitioners ?? [];
      const existingPractitioners = state.practitioners;
      const allPractitioners = existingPractitioners.concat(newPractitioners);
      const uniquePractitioners = allPractitioners.filter((practitioner, index, self) => {
        return index === self.findIndex((a) => a.providerId === practitioner.providerId);
      });
      const sortBy = state.sortBy || 'createdAt';
      const sortedPractitioners = uniquePractitioners.slice().sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      });
      state.practitioners = sortedPractitioners;
      state.stats = action.payload.stats ?? {};
    },

    // UPDATE ACCOUNT
    updatePractitionerSuccess(state, action: PayloadAction<IPractitionerResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const practitioner_data = action.payload.practitioner;
        if (practitioner_data) {
          const practitionerIndex = state.practitioners.findIndex(practitioner => practitioner.providerId === practitioner_data.providerId);
          if (practitionerIndex !== -1) {
            // Create a new object reference for the updated practitioner
            state.practitioners = [
              ...state.practitioners.slice(0, practitionerIndex),
              practitioner_data,
              ...state.practitioners.slice(practitionerIndex + 1),
            ];
            state.practitioner = practitioner_data;
          }
        }
      }
    },


    // GET ACCOUNT
    getPractitionerSuccess(state, action: PayloadAction<IPractitionerResponse>) {
      state.isLoading = false;
      state.practitioner = action.payload.practitioner || null;
    },

    // GET ACCOUNT LOCATIONS
    getPractitionerLocationsSuccess(state, action: PayloadAction<IPractitionerResponse>) {
      state.isLoading = false;
      state.practitioner = action.payload.practitioner || null;
    },

    // GET PROVIDER VPA QR IMAGE DATA
    getProviderVpaQrImageDataSuccess(state, action: PayloadAction<VpaQrImageDataResponse>) {
      state.isLoading = false;
      state.vpaQrImages[action.payload.providerId] = action.payload.qrImage || null;
    },

    // UPDATE ACCOUNT STATUS
    updatePractitionersStatusSuccess(state, action: PayloadAction<IPractitionerFlagsResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const { providerIds, status } = action.payload;
        providerIds.forEach(providerId => {
          const practitionerIndex = state.practitioners.findIndex(practitioner => practitioner.providerId === providerId);
          if (practitionerIndex !== -1) {
            state.practitioners[practitionerIndex].status = status || 'active';
          }
        });
      }
    },

    // UPDATE BOOKMARK STATUS
    updateBookmarkSuccess(state, action: PayloadAction<IPractitionerFlagsResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const { providerIds, bookmarked } = action.payload;
        providerIds.forEach(providerId => {
          const practitionerIndex = state.practitioners.findIndex(practitioner => practitioner.providerId === providerId);
          // if (practitionerIndex !== -1) {
          //   state.practitioners[practitionerIndex].bookmarked = bookmarked;
          // }
        });
      }
    },

    //  SORT & FILTER PRODUCTS
    sortByPractitioners(state, action: PayloadAction<string>) {
      state.sortBy = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  sortByPractitioners,
  getPractitionerSuccess,
  getPractitionersSuccess,
  updatePractitionerSuccess,
  updateBookmarkSuccess,
  getPractitionerLocationsSuccess,
  updatePractitionersStatusSuccess,
  getProviderVpaQrImageDataSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getPractitioners(page: number, rowsPerPage: number, status?: string, sortBy?: string, app?: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      if (sortBy) {
        dispatch(sortByPractitioners(sortBy))
      }
      let url = '/management/providers/practitioners_list?page=' + page + '&rowsPerPage=' + rowsPerPage;
      url = status ? url + '&status=' + status : url;
      url = sortBy ? url + '&sortBy=' + sortBy : url;
      const response = await axiosBrightHub.get(url);
      dispatch(getPractitionersSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLocationsByPractitioner(practitioner: string, app?: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      let url = '/practitioner/manage/get_locations_by_practitioner?app=' + (app || 'brightpay') + '&practitioner_id=' + practitioner;
      const response = await axiosBrightHub.get(url);
      dispatch(getPractitionerLocationsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProviderVpaQrImageData(providerId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      let url = '/practitioner/manage/get_location_vpa_qr_image_data?providerId=' + providerId;
      const response = await axiosBrightHub.get(url);
      dispatch(getProviderVpaQrImageDataSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const getPractitioner = (providerId: string): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axiosBrightHub.post('/management/practitioner/details', { providerId });
    dispatch(getPractitionerSuccess(response.data));
  } catch (error) {
    console.error(error);
    dispatch(hasError(error));
  }
};

// ----------------------------------------------------------------------

export function updatePractitioner(providerId: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/update', { providerId, data });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEducation(practitionerId: string, eduItemId: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/update_education', { providerId: practitionerId, eduItemId, data });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEducation(practitionerId: string, eduItemId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/delete_education', { providerId: practitionerId, eduItemId });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addEducation(practitionerId: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/add_education', { providerId: practitionerId, data });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePractitionersStatus(providerIds: string[], status: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/practitioner/manage/update_status', { providerIds, status });
      dispatch(updatePractitionersStatusSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateBookmark(providerIds: string[], bookmarked: boolean) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/practitioner/manage/add_bookmark', { providerIds });
      dispatch(updateBookmarkSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateMembership(practitionerId: string, membershipId: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/update_membership', { providerId: practitionerId, membershipId, data });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteMembership(practitionerId: string, membershipId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/delete_membership', { providerId: practitionerId, membershipId });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addMembership(practitionerId: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/add_membership', { providerId: practitionerId, data });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePublication(practitionerId: string, publicationId: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/update_publication', { providerId: practitionerId, publicationId, data });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deletePublication(practitionerId: string, publicationId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/delete_publication', { providerId: practitionerId, publicationId });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addPublication(practitionerId: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/add_publication', { providerId: practitionerId, data });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateAward(practitionerId: string, awardId: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/update_award', { providerId: practitionerId, awardId, data });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteAward(practitionerId: string, awardId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/delete_award', { providerId: practitionerId, awardId });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addAward(practitionerId: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/add_award', { providerId: practitionerId, data });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateExperience(practitionerId: string, experienceId: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/update_experience', { providerId: practitionerId, experienceId, data });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteExperience(practitionerId: string, experienceId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/delete_experience', { providerId: practitionerId, experienceId });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addExperience(practitionerId: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/add_experience', { providerId: practitionerId, data });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}