import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
// utils
import axiosBrightHub from 'src/utils/axiosBrightHub';
//
import { RootState, AppDispatch } from '../store';

// types
import { IPracticeItem } from 'src/types/practice';

// ----------------------------------------------------------------------

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

interface IPracticeResponse {
  practice?: IPracticeItem;
  practices?: IPracticeItem[];
  stats?: {
    [key: string]: number;
  };
  error?: string;
  message?: string;
}

interface VpaQrImageDataResponse {
  providerId: string;
  qrImage: string;
}

interface IPracticeFlagsResponse {
  providerIds: string[];
  status?: string;
  bookmarked?: boolean;
  error?: string;
  message?: string;
}

interface PracticesState {
  isLoading: boolean;
  error: string | null;
  practices: IPracticeItem[];
  vpaQrImages: {
    [key: string]: string | null;
  },
  stats: {
    [key: string]: number;
  };
  practice: IPracticeItem | null;
  category: string | null;
  other: string | null;
  sortBy: string | null;
}

const initialState: PracticesState = {
  isLoading: false,
  error: null,
  practices: [],
  vpaQrImages: {},
  stats: {},
  practice: null,
  category: null,
  other: null,
  sortBy: 'createdAt',
};

const slice = createSlice({
  name: 'practice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ACCOUNTS
    getPracticesSuccess(state, action: PayloadAction<IPracticeResponse>) {
      state.isLoading = false;
      const newPractices = action.payload.practices ?? [];
      const existingPractices = state.practices;
      const allPractices = existingPractices.concat(newPractices);
      const uniquePractices = allPractices.filter((practice, index, self) => {
        return index === self.findIndex((a) => a.providerId === practice.providerId);
      });
      const sortBy = state.sortBy || 'createdAt';
      const sortedPractices = uniquePractices.slice().sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      });
      state.practices = sortedPractices;
      state.stats = action.payload.stats ?? {};
    },

    // UPDATE ACCOUNT
    updatePracticeSuccess(state, action: PayloadAction<IPracticeResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const practice_data = action.payload.practice;
        if (practice_data) {
          const practiceIndex = state.practices.findIndex(practice => practice.providerId === practice_data.providerId);
          if (practiceIndex !== -1) {
            state.practices[practiceIndex] = practice_data; // Ensure we only update when practice_data is not null.
          }
        }
      }
    },

    // GET ACCOUNT
    getPracticeSuccess(state, action: PayloadAction<IPracticeResponse>) {
      state.isLoading = false;
      state.practice = action.payload.practice || null;
    },

    // GET ACCOUNT LOCATIONS
    getPracticeLocationsSuccess(state, action: PayloadAction<IPracticeResponse>) {
      state.isLoading = false;
      state.practice = action.payload.practice || null;
    },

    // GET PROVIDER VPA QR IMAGE DATA
    getProviderVpaQrImageDataSuccess(state, action: PayloadAction<VpaQrImageDataResponse>) {
      state.isLoading = false;
      state.vpaQrImages[action.payload.providerId] = action.payload.qrImage || null;
    },

    // UPDATE ACCOUNT STATUS
    updatePracticesStatusSuccess(state, action: PayloadAction<IPracticeFlagsResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const { providerIds, status } = action.payload;
        providerIds.forEach(providerId => {
          const practiceIndex = state.practices.findIndex(practice => practice.providerId === providerId);
          if (practiceIndex !== -1) {
            state.practices[practiceIndex].status = status || 'active';
          }
        });
      }
    },

    // UPDATE BOOKMARK STATUS
    updateBookmarkSuccess(state, action: PayloadAction<IPracticeFlagsResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const { providerIds, bookmarked } = action.payload;
        providerIds.forEach(providerId => {
          const practiceIndex = state.practices.findIndex(practice => practice.providerId === providerId);
          // if (practiceIndex !== -1) {
          //   state.practices[practiceIndex].bookmarked = bookmarked;
          // }
        });
      }
    },

    //  SORT & FILTER PRODUCTS
    sortByPractices(state, action: PayloadAction<string>) {
      state.sortBy = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { 
  hasError,
  startLoading,
  sortByPractices,
  getPracticeSuccess,
  getPracticesSuccess,
  updatePracticeSuccess,
  updateBookmarkSuccess,
  getPracticeLocationsSuccess,
  updatePracticesStatusSuccess,
  getProviderVpaQrImageDataSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getPractices(page: number, rowsPerPage: number, status?: string, sortBy?: string, app?: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      if (sortBy) {
        dispatch(sortByPractices(sortBy))
      }
      let url = '/management/providers/practices_list?page=' + page + '&rowsPerPage=' + rowsPerPage;
      url = status ? url + '&status=' + status : url;
      url = sortBy ? url + '&sortBy=' + sortBy : url;
      const response = await axiosBrightHub.get(url);
      dispatch(getPracticesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLocationsByPractice(practice: string, app?: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      let url = '/practice/manage/get_locations_by_practice?app=' + (app || 'brightpay') + '&practice_id=' + practice;
      const response = await axiosBrightHub.get(url);
      dispatch(getPracticeLocationsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProviderVpaQrImageData(providerId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      let url = '/practice/manage/get_location_vpa_qr_image_data?providerId=' + providerId;
      const response = await axiosBrightHub.get(url);
      dispatch(getProviderVpaQrImageDataSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const getPractice = (providerId: string): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axiosBrightHub.post('/management/practice/details', { providerId });
    dispatch(getPracticeSuccess(response.data));
  } catch (error) {
    console.error(error);
    dispatch(hasError(error));
  }
};

// ----------------------------------------------------------------------

export function updatePractice(providerId: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/practice/manage/update', { providerId, data });
      dispatch(updatePracticeSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePracticesStatus(providerIds: string[], status: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/practice/manage/update_status', { providerIds, status });
      dispatch(updatePracticesStatusSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateBookmark(providerIds: string[], bookmarked: boolean) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/practice/manage/add_bookmark', { providerIds });
      dispatch(updateBookmarkSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}